import styled from "@emotion/styled";
import React from "react";

const CardStep = (props) => {

    const { values } = props;

    return (
        <CardFake>
            <Order>{values.order}</Order>
            <Image>
                <img src={values.image} alt={values.title} />
            </Image>
            <Wrapper>
                <h2>{values.title}</h2>
                {/* <p>{values.description}</p> */}
            </Wrapper>
        </CardFake>
    );
};

export default CardStep;

const CardFake = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    height: calc(100% - 4rem);
    align-items: center;
    justify-content: center;
    width: calc(100% - 4rem);
    background: #ffffff;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
        0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
    z-index: 1;
`;

const Order = styled.div`
    position: absolute;
    padding: 0.1rem;
    font-weight: bold;
    border-radius: 50%;
    display: flex;
    top: -10px;
    z-index: 2;
    justify-content: center;
    align-items: center;
    width: calc(30px - 0.2rem);
    height: calc(30px - 0.2rem);
    color: #ffffff;
    background-color: #b31d15;
`;

const Image = styled.div`
    min-width: 100px;
    margin: 0 auto;
    img {
        widht: 100%;
        object-fit: cover;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    align-items: center;
    text-align: center;

    h2 {
        color: #222222;
        font-weight: 900;
        font-size: 1rem;
    }

    p {
        color: #616161;
        font-size: 0.9rem;
    }
`;
