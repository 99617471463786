import LayoutMain from "./components/layouts/main/Main";
import Form from "./pages/Form";

function App() {
  return (
    <LayoutMain component={Form}/>
  );
}

export default App;
