import React, { useState } from "react";
import Card from "../../styled-components/Card";
import styled from "@emotion/styled";
import { Formik, Form } from "formik";
import DefaultField from "../common/DefaultField";
import Text from "../../styled-components/Text";
import DefaultPhone from "../common/DefaultPhone";
import { Button, IconButton, InputAdornment } from "@mui/material";
import { formSchema } from "../../utils/schema";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import DefaultComplete from "../common/DefaultComplete";
import useUtils from "../../hooks/useUtils";

const FormContent = (props) => {
    const { registerUser } = props;

    const { countries, companies } = useUtils();

    const initialValues = {
        name: "",
        lastName: "",
        email: "",
        country: "",
        company: "",
        phone: "",
        password: "",
    };
    const url = "https://app.aden.org/auth";

    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = (values) => registerUser(values);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    return (
        <Card>
            <Container>
                <Text
                    color="#222"
                    fontSize="2rem"
                    fontWeight="900"
                    textAlign="center"
                >
                    {/* ¡Regístrate, realiza la actividad y obtén tu certificado! */}
                    Regístrate en Acrópolis
                </Text>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={formSchema}
                >
                    {({ values, setFieldValue }) => (
                        <FormStyled>
                            <DefaultField
                                variant="outlined"
                                type="text"
                                name="name"
                                label="Nombre"
                            />
                            <DefaultField
                                variant="outlined"
                                type="text"
                                name="lastName"
                                label="Apellido"
                            />
                            <Col>
                                <DefaultComplete
                                    name="country"
                                    label="País"
                                    options={countries}
                                    freeSolo={false}
                                    setFieldValue={setFieldValue}
                                    variant="outlined"
                                />
                                <DefaultComplete
                                    objectMod={{
                                        empresa_nombre: "value",
                                        empresa_id: false,
                                    }}
                                    name="company"
                                    label="Empresa"
                                    options={[]}
                                    freeSolo={true}
                                    setFieldValue={setFieldValue}
                                    variant="outlined"
                                />
                            </Col>
                            <DefaultField
                                variant="outlined"
                                type="text"
                                name="email"
                                label="Correo electrónico"
                            />
                            <DefaultPhone
                                name="phone"
                                label="Teléfono"
                                value={values.phone}
                                setFieldValue={setFieldValue}
                                variant="outlined"
                            />
                            <DefaultField
                                variant="outlined"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                label="Contraseña"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={
                                                    handleClickShowPassword
                                                }
                                                edge="end"
                                            >
                                                {showPassword ? (
                                                    <VisibilityOff
                                                        fontSize="small"
                                                        color="primary"
                                                    />
                                                ) : (
                                                    <Visibility fontSize="small" />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <ButtonSubmit
                                type="submit"
                                fullWidth
                                color="primary"
                                variant="contained"
                            >
                                Registrarme
                            </ButtonSubmit>
                            <ButtonSubmit
                                onClick={() => window.open(url)}
                                fullWidth
                                color="primary"
                            >
                                Iniciar sesión
                            </ButtonSubmit>
                        </FormStyled>
                    )}
                </Formik>
            </Container>
        </Card>
    );
};

export default FormContent;

const Container = styled.div`
    height: auto;
    padding: 32px;
`;

const FormStyled = styled(Form)`
    height: calc(100% - 300px);
    gap: 32px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 32px;
`;

const ButtonSubmit = styled(Button)`
    height: 50px;
    font-size: 16px;
    border-radius: 5px;
`;

const Col = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
`;
