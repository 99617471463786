import styled from '@emotion/styled'
import React from 'react'
import Card from '../../styled-components/Card'
import Image from '../../styled-components/Image'
import error from "../../assets/images/error.svg";
import Text from '../../styled-components/Text';
import { Button } from '@mui/material';
// import LoginIcon from '@mui/icons-material/Login';

const ErrorResponse = () => {

    // const { values } = props

    return (
        <Card>
            <SuccessContainer>
                <Image w="220px" src={error} alt="Image"/>
                <div>
                    <Text textAlign="center" color="#b31d15" fontWeight="bold" fontSize="18px">¡Ocurrió un error al registrarse!</Text>
                </div>
                <Button onClick={() => window.location.reload()} variant='contained' color="primary">Reintentar</Button>
                {/* <LoadingButton>
                    <Message>
                        <Text color="#fff" fontWeight="bold">Ingresar al campus</Text>
                    </Message>
                    <Icon>
                        <LoginIcon/>
                    </Icon>
                </LoadingButton> */}
            </SuccessContainer>
        </Card>
    )
}

export default ErrorResponse

const SuccessContainer = styled.div`
    padding: 24px 16px 40px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
`

// const LoadingButton = styled.div`
//     cursor: pointer;
//     user-select: none;
//     width: 300px;
//     height: 50px;
//     border-radius: 5px;
//     background-color: #b31d15;
//     display: grid;
//     grid-template-columns: auto 80px;
//     transition: ease-in-out .3s all;
//     :hover{
//         filter: brightness(1.1);
//     }
// `

// const Icon = styled.div`
//     width: 100%;
//     height: 100%;
//     border-top-right-radius: 5px;
//     border-bottom-right-radius: 5px;
//     background-color: #e8bcb9;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     svg{
//         color: #b31d15;
//     }
// `

// const Message = styled.div`
//     height: 100%;
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// `