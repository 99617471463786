import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { ErrorMessage } from "formik";
import styled from "@emotion/styled";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    paper: {
        backgroundColor: "rgb(255,255,255) !important",
    },
});

const DefaultComplete = (props) => {
    const {
        options = [],
        label,
        name,
        freeSolo,
        setFieldValue,
        variant = "outlined",
        objectMod,
    } = props;

    const classes = useStyles();

    return (
        <AutocompleteFake
            disablePortal
            freeSolo={freeSolo}
            options={options}
            name={name}
            variant={variant}
            onBlur={(e) => {
                const { value, name } = e.target;
                const item = options.find(
                    (op) => op.name === value || op.empresa_nombre === value
                );

                if (!item) {
                    let result = {};
                    for (const key in objectMod) {
                        if (objectMod[key] === "value") {
                            result = { ...objectMod, [key]: value };
                        }
                    }
                    setFieldValue(name, result);
                } else {
                    setFieldValue(name, item);
                }
                !value && setFieldValue(name, "");
            }}
            color="primary"
            size="small"
            fullWidth
            getOptionLabel={(option) => option.name || option.empresa_nombre}
            classes={{ paper: classes.paper }}
            renderInput={(params) => (
                <TextFieldFake
                    {...params}
                    label={label}
                    name={name}
                    InputLabelProps={{ shrink: true }}
                    helperText={
                        <ErrorMessage name={name} component={CustomError} />
                    }
                />
            )}
        />
    );
};

export default DefaultComplete;

const AutocompleteFake = styled(Autocomplete)`
    display: flex;
    flex-direction: column;
`;

const CustomError = styled.span`
    font-size: 0.75rem;
    font-weight: 700;
    font-family: Lato;
    color: #d24335;
`;

const TextFieldFake = styled(TextField)`
    label {
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .MuiInputBase-root {
        /* color: white; */
        height: 59px;
    }
    svg {
        color: white;
    }
`;
