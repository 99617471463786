import styled from '@emotion/styled'
import React from 'react'
import Card from '../../styled-components/Card'
import Image from '../../styled-components/Image'
import confirm from "../../assets/images/confirm.svg";
import Text from '../../styled-components/Text';
import LoginIcon from '@mui/icons-material/Login';
import { encodeData } from '../../helpers/encode.helper';
import useQueryParams from '../../hooks/useQueryParams';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

const EnterCampus = (props) => {

    const { values } = props

    const { program_id = false } = useQueryParams();
    const hashProgram = encodeData(program_id)

    const handleLogin = () => {
        const url = `https://app.aden.org/autologin?token=${values.response_data.token}&redirecturi=asignatura/${values.response_data.assignment}?program=${hashProgram}&origin=diplomado`
        window.open(url)
    }

    return (
        <Card>
            <Texts>
                <Text textAlign="center" color="#b31d15" fontWeight="bold" fontSize="18px">¡Bienvenido {values.name} {values.lastName}!</Text>
                <Text textAlign="center" color="#555">Has click para ingresar al campus</Text>
            </Texts>
            <SuccessContainer>
                {/* <Image w="220px" src={confirm} alt="Image" /> */}
                <Center>
                    <CountdownCircleTimer
                        onComplete={() => {
                            // do your stuff here
                            // window.open('https://app.aden.org')
                            handleLogin()
                            return [true, 1500] // repeat animation in 1.5 seconds
                        }}
                        isPlaying
                        duration={15}
                        colors={['#3bd39c', '#b31d15']}
                        colorsTime={[15, 0]}
                    >
                        {({ remainingTime }) => <Redirection>
                            <Text color="#555" fontSize="14px" fontWeight="bold">Redirigir en:</Text>
                            <Counter>{remainingTime}</Counter>
                        </Redirection>}
                    </CountdownCircleTimer>
                </Center>

                <LoadingButton onClick={handleLogin}>
                    <Message>
                        <Text color="#fff" fontWeight="bold">Ingresar al campus</Text>
                    </Message>
                    <Icon>
                        <LoginIcon />
                    </Icon>
                </LoadingButton>
            </SuccessContainer>

        </Card>
    )
}

export default EnterCampus

const SuccessContainer = styled.div`
    padding: 24px 16px 40px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
`

const LoadingButton = styled.div`
    cursor: pointer;
    user-select: none;
    width: 300px;
    height: 50px;
    border-radius: 5px;
    background-color: #b31d15;
    display: grid;
    grid-template-columns: auto 80px;
    transition: ease-in-out .3s all;
    :hover{
        filter: brightness(1.1);
    }
`

const Icon = styled.div`
    width: 100%;
    height: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #e8bcb9;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
        color: #b31d15;
    }
`

const Message = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Counter = styled.div`
    font-size: 36px;
    color: #999;
    font-weight: bold;
`

const Center = styled.div`
    display: flex;
    justify-content: center;
    margin: 24px 0;
`

const Redirection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
`

const Texts = styled.div`
    margin: 36px 0 0 0;
`