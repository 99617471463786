import * as yup from "yup";

export const formSchema = yup.object().shape({
    name: yup
        .string()
        .nullable()
        .min(3, "Mínimo 3 caracteres")
        .max(140, "Máximo 140 caracteres")
        .required("Nombre obligatorio"),
    lastName: yup
        .string()
        .nullable()
        .min(3, "Mínimo 3 caracteres")
        .max(140, "Máximo 140 caracteres")
        .required("Apellido obligatorio"),
    country: yup
        .object()
        .nullable()
        .required("País obligatorio"),
    company: yup
        .object()
        .nullable()
        .required("Empresa obligatorio"),
    email: yup
        .string()
        .nullable()
        .min(8, "Mínimo 8 caracteres")
        .max(140, "Máximo 140 caracteres")
        .required("Correo electrónico obligatorio"),
    phone: yup
        .string()
        .nullable()
        .min(8, "Mínimo 8 caracteres")
        .required("Teléfono obligatorio"),
    password: yup
        .string()
        .nullable()
        .min(8, "Mínimo 8 caracteres")
        .max(256, "Máximo 256 caracteres")
        .required("Contraseña obligatorio"),
});
