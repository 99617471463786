import axios from "axios"

const SISAPI_URL = process.env.REACT_APP_SISAPI;

export const registerUserService = async (body) => {
    try {
        const request = await axios.post(`${SISAPI_URL}/v1/acropolis/auth/register-from-scorm`, body)
        return request.data
    } catch (error) {
        throw Error(error)
    }
}