import styled from "@emotion/styled";
import React, { useState } from "react";
import { sendMail } from "../../api/sis-api";
import useProgram from "../../hooks/useProgram";
import useQueryParams from "../../hooks/useQueryParams";
import { registerUserService } from "../../services/auth";
import DefaultLoading from "../common/DefaultLoading";
import { emailTemplate, emailTemplateRegister } from "../emailTemplate";
import Banner from "../organism/Banner";
import FormContent from "../organism/Form";
// import Information from "../organism/Information";
import Steps from "../organism/Steps";
import EnterCampus from "./EnterCampus";
import ErrorResponse from "./ErrorResponse";

const FormRegister = () => {
    const [confirm, setConfirm] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const { program_id = false } = useQueryParams();

    const { program } = useProgram(parseInt(program_id));

    const handleSendMail = (body) => {
        const bodyInternalMail = {
            from_email: "tecnologia@aden.org",
            to_emails: "aden.proyecto100@aden.org",
            subject: "Nuevo registro de usuario RISE",
            html_content: emailTemplate(body),
        };

        const bodyEmailRegister = {
            from_email: "aden.proyecto100@aden.org",
            to_emails: [body?.form?.email],
            subject: `¡Felicitaciones, estás cerca de obtener tu certificado en el Curso ${body?.form?.diplomat_name}`,
            html_content: emailTemplateRegister(body),
        };

        sendMail(bodyInternalMail);
        sendMail(bodyEmailRegister);
    };

    const registerUser = async (form) => {
        const { name, lastName, country, company, email, phone, password } =
            form;
        const body = {
            form: {
                first_name: name,
                last_name: lastName,
                email: email,
                phone: phone,
                country_id: country?.id,
                password: password,
                company_name: company?.empresa_nombre,
                program_id: parseInt(program_id),
                language: "es",
                diplomat_name: program?.scorm_name || "Indefinido",
                landing_url: `https://rise.aden.org/?program_id=${parseInt(
                    program_id
                )}`,
            },
            extra_data: {},
        };

        setLoading(true);
        try {
            const loginResponse = await registerUserService(body);
            handleSendMail(body);

            setConfirm({ ...form, ...loginResponse });
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <FormWrapper>
            <ContainerGap>
                <Banner />
                <Steps />
                {/* <Information /> */}
            </ContainerGap>
            <ContainerGap>
                {!error ? (
                    loading ? (
                        <DefaultLoading title="Generando registro..." />
                    ) : !confirm ? (
                        <FormContent registerUser={registerUser} />
                    ) : (
                        <EnterCampus values={confirm} />
                    )
                ) : (
                    <ErrorResponse />
                )}
            </ContainerGap>
        </FormWrapper>
    );
};

export default FormRegister;

const FormWrapper = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 16px;
    @media (max-width: 1200px) {
        display: flex;
        flex-direction: column-reverse;
    }
`;

const ContainerGap = styled.div`
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    gap: 16px;
`;
