import axios from "axios"

const BASE_URL = 'https://app.aden.org'

export const getProgramById = async (id) => {
    try {
        const request = await axios.get(`${BASE_URL}/mock/ecommerce.json`)
        return request.data.response_data.products.find(item => item.id === id)
    } catch (error) {
        return { error }
    }
}