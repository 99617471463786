import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import React from 'react'
import Card from '../../styled-components/Card';
import Text from '../../styled-components/Text';

const DefaultLoading = (props) => {

    const { title = 'Cargando' } = props

    return <CardFake>
        <Qubo>
            <CircularProgress />
        </Qubo>
        <Text color='#b31d15' fontWeight="bold">{title}</Text>
    </CardFake>
}

export default DefaultLoading

const CardFake = styled(Card)`
    width: 100%;
    height: 100%;
    max-height: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    align-items: center;
`

const Qubo = styled.div`
    padding: 16px;
    border-radius: 20px;
    background-color: #f7e2e1;
    display: flex;
    justify-content: center;
    align-items: center;
`