import styled from '@emotion/styled'
import React from 'react'
import NavBar from '../navbar/NavBar'

const LayoutMain = (props) => {

    const { component: Component } = props

    return (
        <Layout>
            <NavBar/>
            <Main>
                {<Component/>}
            </Main>
        </Layout>
    )
}

export default LayoutMain

const Layout = styled.div`
    background-color: #F9F9F9;
    min-height: 100vh;
`

const Main = styled.div`
    padding: 48px;
`