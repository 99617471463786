const useQueryParams = () => {

    // Obtener paramas
    let query = window.location.search
    // convierto en array
    query = query.slice(1).split('&')

    let object = {}
    
    query.forEach(item => {
        let params = item.split('=')
        object[params[0]] = params[1]
    })

    return object
}

export default useQueryParams