import { useEffect, useState } from "react"
import { getProgramById } from "../services/programs"

const useProgram = (id = false) => {
    const [program, setProgram] = useState(null)

    useEffect(() => {
        !program && getProgram(id)
    }, [program, id])

    const getProgram = async (id_program) => {
        const program = await getProgramById(id_program)
        if (program.error) {
            alert('Ocurrio un error')
        }
        else{
            setProgram(program)
        }
    }
    
    return { program }
}

export default useProgram