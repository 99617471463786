import styled from "@emotion/styled";
import React from "react";
import CardStep from "./molecules/CardStep";
import RegistroImage from "../../assets/images/registro-acropolis.png";
import ActividadImage from "../../assets/images/actividad-acropolis.png";
import CertificadoImage from "../../assets/images/certificado-acropolis.png";

const Steps = () => {
    const stepsArray = [
        {
            order: 1,
            title: "Regístrate en Acrópolis",
            description:
                "Al registrarte, obtendrás acceso a Acrópolis y serás matriculado en el Diplomado",
            image: RegistroImage,
        },
        {
            order: 2,
            title: "Realiza la actividad",
            description:
                "Completa y entraga la actividad integradora del primer módulo",
            image: ActividadImage,
        },
        {
            order: 3,
            title: "Obtén tu certificado",
            description:
                "Al aprobar la actividad, podrás descargar tu certificado de finalización",
            image: CertificadoImage,
        },
    ];

    return (
        <Wrapper>
            {stepsArray.map((steps) => (
                <CardStep key={steps.order} values={steps} />
            ))}
        </Wrapper>
    );
};

export default Steps;

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    align-items: center;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;
