import styled from "@emotion/styled";
import React from "react";
import Card from "../../styled-components/Card";
import Image from "../../styled-components/Image";
import Text from "../../styled-components/Text";
import banner from "../../assets/images/banner-aden.svg";
import useProgram from "../../hooks/useProgram";
import useQueryParams from "../../hooks/useQueryParams";

const Banner = () => {

    const { program_id = false } = useQueryParams();
    const { program } = useProgram(parseInt(program_id));
    
    return (
        <Card>
            <Container>
                <Texts>
                    <Text fontSize="2rem" fontWeight="bold" color="#B31D15">
                        ¡Felicitaciones, estás cerca de obtener tu certificado en el Curso {program?.scorm_name || 'Indefinido'}!
                    </Text>
                    <Text fontSize="1.1rem" fontWeight="400" color="#222222">
                        Te invitamos a realizar una actividad práctica dentro de nuestro Campus Acrópolis, la plataforma educativa exclusiva para alumnos de ADEN Educational Group.
                        <br/>
                        Sigue los siguientes pasos:
                    </Text>
                </Texts>
                <Texts>
                    <Image width="169px" src={banner} />
                </Texts>
            </Container>
        </Card>
    );
};

export default Banner;

const Container = styled.div`
    display: grid;
    grid-template-columns: 5fr 1fr;
    padding: 26px 32px 32px 32px;
    gap: 12px;
    height: calc(351px - 64px);

    @media (max-width: 768px) {
        height: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
    }
`;

const Texts = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    gap: 16px;
    flex-direction: column;
`;
