import React from "react";


import { ErrorMessage, Field } from "formik";

import styled from "@emotion/styled";
import { TextField } from "@mui/material";

const DefaultField = (props) => {
    const { name, type, label, placeholder, variant = 'standard' } = props;

    // RETURN
    return (
        <Field
            as={TextFieldFake}
            name={name}
            helperText={<ErrorMessage name={name} component={CustomError} />}
            type={type}
            label={label}
            variant={variant}
            placeholder={placeholder}
            color="primary"
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...props}
        />
    );
};

export default DefaultField;

const CustomError = styled.span`
    font-size: 0.75rem;
    font-weight: 700;
    color: #b31d15;
`;

const TextFieldFake = styled(TextField)`
    /* padding: 16px 0; */
    .MuiInputBase-root{
        height: 59px;
        border-radius: 5px;
    }
`