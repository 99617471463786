import FormRegister from "../components/templates/FormRegister"

const Form = () => {

    return (
        <>
            <FormRegister/>
        </>
    )
}

export default Form
