import styled from '@emotion/styled'
import React from 'react'
import Image from '../../../styled-components/Image'
import logo from "../../../assets/images/aden.png";

const NavBar = () => {
    return (
        <Container>
            <Image src={logo} w="140px"/>
        </Container>
    )
}

export default NavBar

const Container = styled.div`
    width: 100%;
    height: 65px;
    background: #FFFFFF;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06), inset 0px 1px 0px #F1F1F1;
    display: flex;
    align-items: center;
    justify-content: center;
`